var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-masthead",
    {
      scopedSlots: _vm._u([
        {
          key: "mds-page-shell-vertical-nav",
          fn: function() {
            return [_c("WorkingDashboardNav")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("h3", { staticClass: "title" }, [
            _vm._v(
              " Unmapped " +
                _vm._s(
                  _vm.activeClass.charAt(0).toUpperCase() +
                    _vm.activeClass.slice(1)
                ) +
                " "
            )
          ]),
          _vm.isLoading ? _c("mds-loader") : _vm._e(),
          !_vm.isLoading
            ? _c("mds-select", {
                attrs: { label: "Locale", options: _vm.locales },
                on: { change: _vm.getData },
                model: {
                  value: _vm.selectedLocale,
                  callback: function($$v) {
                    _vm.selectedLocale = $$v
                  },
                  expression: "selectedLocale"
                }
              })
            : _vm._e(),
          !_vm.isLoading
            ? _c("mds-data-table", {
                attrs: {
                  "row-hover": "",
                  "header-configs": _vm.headers,
                  "row-data": _vm.rows
                },
                on: {
                  "mds-data-table-header-action-click": _vm.onPopoverActionClick
                }
              })
            : _vm._e(),
          _c(
            "mds-popover",
            {
              attrs: {
                "triggered-by": "date-filter",
                position: "bottom-center",
                width: "300px",
                title: "Filter"
              },
              scopedSlots: _vm._u([
                {
                  key: "mds-popover-actions",
                  fn: function() {
                    return [
                      _c(
                        "mds-button",
                        {
                          attrs: { size: "medium" },
                          on: { click: _vm.clearSelection }
                        },
                        [_vm._v("Clear")]
                      )
                    ]
                  },
                  proxy: true
                }
              ]),
              model: {
                value: _vm.model1,
                callback: function($$v) {
                  _vm.model1 = $$v
                },
                expression: "model1"
              }
            },
            [
              _c(
                "AppDatePicker",
                _vm._b(
                  { on: { selected: _vm.selectedStartDate } },
                  "AppDatePicker",
                  _vm.startDate,
                  false
                )
              ),
              _vm._v(" - "),
              _c(
                "AppDatePicker",
                _vm._b(
                  {
                    staticClass: "clicked",
                    on: { selected: _vm.selectedEndDate }
                  },
                  "AppDatePicker",
                  _vm.endDate,
                  false
                )
              )
            ],
            1
          ),
          _c(
            "span",
            [
              _vm.count >= _vm.pageSize
                ? _c("mds-pagination", {
                    attrs: {
                      "show-items-info": "",
                      "show-items-select": "",
                      page: _vm.currentPage,
                      "total-items": _vm.count,
                      pageSize: _vm.pageSize
                    },
                    on: { "mds-pagination-page-changed": _vm.pageChanged }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }