import { render, staticRenderFns } from "./TermEditAttribute.vue?vue&type=template&id=f8f45114&scoped=true&"
import script from "./TermEditAttribute.vue?vue&type=script&lang=js&"
export * from "./TermEditAttribute.vue?vue&type=script&lang=js&"
import style0 from "./TermEditAttribute.vue?vue&type=style&index=0&id=f8f45114&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8f45114",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1373073072/src/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f8f45114')) {
      api.createRecord('f8f45114', component.options)
    } else {
      api.reload('f8f45114', component.options)
    }
    module.hot.accept("./TermEditAttribute.vue?vue&type=template&id=f8f45114&scoped=true&", function () {
      api.rerender('f8f45114', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/TermManagement/TermEditAttribute.vue"
export default component.exports