import { render, staticRenderFns } from "./SchemaGraph.vue?vue&type=template&id=03819e72&scoped=true&"
import script from "./SchemaGraph.vue?vue&type=script&lang=js&"
export * from "./SchemaGraph.vue?vue&type=script&lang=js&"
import style0 from "./SchemaGraph.vue?vue&type=style&index=0&id=03819e72&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03819e72",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3430627143/src/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('03819e72')) {
      api.createRecord('03819e72', component.options)
    } else {
      api.reload('03819e72', component.options)
    }
    module.hot.accept("./SchemaGraph.vue?vue&type=template&id=03819e72&scoped=true&", function () {
      api.rerender('03819e72', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/SchemaManagement/SchemaGraph.vue"
export default component.exports